import { ExclamationCircleOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import { DeliveryWater } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

import { Modal } from 'antd';
import Api from 'src/api';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
interface DeliveryWaterDeleteModalProps {
    api: Api;
    projectId: string;
    companyId: string;
    deliveryWater: DeliveryWater;
    onSuccess: () => void;
}

export default function DeliveryWaterDeleteModal({
    api,
    companyId,
    projectId,
    deliveryWater,
    onSuccess
}: DeliveryWaterDeleteModalProps) {
    return Modal.warning({
        title: <b>{t`Are you sure to delete this water addition record?`}</b>,
        width: 600,
        icon: <ExclamationCircleOutlined />,
        content: t`This action can not be undone.`,
        okText: t`Confirm`,
        okType: 'primary',
        okCancel: true,
        cancelText: t`Close`,
        onOk() {
            api.deliveryNoteClient
                .deleteDeliveryWater(companyId, projectId, deliveryWater.concreteLoadId, deliveryWater.id)
                .then(() => {
                    SuccessNotification({
                        message: t`Delivery water deleted`
                    });
                    onSuccess();
                })
                .catch(() => {
                    ErrorNotification({
                        message: t`Error deleting delivery water`,
                        description: ''
                    });
                });
        }
    });
}
