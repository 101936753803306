import { t, Trans } from '@lingui/macro';
import {
    DeliveryWater,
    DeliveryWaterOverview
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Card, Divider, Row, Table } from 'antd';
import AmountCol from 'src/app-react/business-page/DeliveryDashboardPage/components/DeliveryDashboardAmount/components/AmountCard/components/AmountCol';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import units from 'src/app-react/constants/marketsData/marketDataAUS/dataValues/units';
import styles from '../../../shared/shared.module.css';
import deliveryWaterColumns from './columns';

interface DeliveryWaterTableProps {
    deliveryWater: DeliveryWater[];
    deliveryWaterOverview: DeliveryWaterOverview;
    maxWaterOnSite: number;
    onDelete: (deliveryWater: DeliveryWater) => void;
}

export function DeliveryWaterTableAUS({
    deliveryWater,
    deliveryWaterOverview,
    maxWaterOnSite,
    onDelete
}: DeliveryWaterTableProps) {
    return (
        <>
            <p className={styles.tableTitle}>
                <Trans>Water added on site</Trans>
            </p>
            <Card data-testid="water-on-site-amount-card-default" style={{ backgroundColor: '#E7EDF5' }}>
                <Row>
                    <AmountCol title={t`Max. water to add`}>
                        <NumberFormatter
                            value={maxWaterOnSite === 0 || maxWaterOnSite === undefined ? '-' : maxWaterOnSite}
                            unit={units.water.toLocaleUpperCase()}
                        />
                    </AmountCol>
                    <AmountCol title={t`Total water added on site`}>
                        <NumberFormatter
                            value={
                                deliveryWaterOverview?.totalWaterOnSite === undefined
                                    ? '-'
                                    : deliveryWaterOverview.totalWaterOnSite
                            }
                            unit={units.water.toLocaleUpperCase()}
                        />
                    </AmountCol>
                    <AmountCol title={t`Remaning water for addition`}>
                        <NumberFormatter
                            value={
                                deliveryWaterOverview?.remainignWaterAddition === undefined
                                    ? '-'
                                    : deliveryWaterOverview.remainignWaterAddition
                            }
                            unit={units.water.toLocaleUpperCase()}
                        />
                    </AmountCol>
                </Row>
            </Card>
            <Divider />
            {deliveryWater.length > 0 && (
                <Table
                    bordered={false}
                    data-testid="customer-property-table"
                    scroll={{ x: 1 }}
                    columns={deliveryWaterColumns({ units, onDelete })}
                    dataSource={deliveryWater}
                    pagination={false}
                />
            )}
            {deliveryWater.length > 0 && <Divider />}
        </>
    );
}
