import {
    DeliveryWater,
    DeliveryWaterOverview
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryWaterTableAUS } from './markets/AUS/DeliveryWaterTableAUS';
import { DeliveryWaterTableDE } from './markets/DE/DeliveryWaterTableDE';

interface DeliveryWaterTableProps {
    deliveryWater: DeliveryWater[];
    deliveryWaterOverview: DeliveryWaterOverview;
    maxWaterOnSite: number;
    onDelete: (deliveryWater: DeliveryWater) => void;
}

function DeliveryWaterTable({
    deliveryWater,
    deliveryWaterOverview,
    maxWaterOnSite,
    onDelete
}: DeliveryWaterTableProps) {
    const { marketId } = useProjectState();

    switch (marketId) {
        case MARKETS.AUS:
            return (
                <DeliveryWaterTableAUS
                    deliveryWater={deliveryWater}
                    deliveryWaterOverview={deliveryWaterOverview}
                    maxWaterOnSite={maxWaterOnSite}
                    onDelete={onDelete}
                />
            );
        default:
            return <DeliveryWaterTableDE deliveryWater={deliveryWater} onDelete={onDelete} />;
    }
}

export default DeliveryWaterTable;
