import BaseCol from '../BaseCol';

interface AmountColProps {
    span?: number;
    children: unknown;
    title: string;
}

function AmountCol({ title, children, span  }: AmountColProps) {
    return (
        <BaseCol span={span} title={title}>
            <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{children}</span>
        </BaseCol>
    );
}

export default AmountCol;
