import { DeliveryWater } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto";

interface DeliveryWaterTableProps {
    deliveryWater: DeliveryWater[];
    onDelete: (deliveryWater: DeliveryWater) => void;
}

export function DeliveryWaterTableDE({ deliveryWater, onDelete }: DeliveryWaterTableProps) {
    return <></>;
}
