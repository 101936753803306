import { BasicConcreteLoadWithOrderId } from '@nexploretechnology/concreting-core-client/concrete.load-client/concrete.load.dto';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
    DeliveryNoteDetailsBP,
    DeliveryWaterOverview
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { useCallback, useEffect, useState } from 'react';
import DeliveryWaterModal from 'src/app-react/business-page/delivery-details/components/delivery-water-modal/DeliveryWaterModal';
import EndUnloadingModal from 'src/app-react/business-page/delivery-note-details/components/EndUnloadingModal/EndUnloadingModal';
import MultipleConcreteTestModal from 'src/app-react/business-page/delivery-note-details/components/MultipleConcreteTestModal/MultipleConcreteTestModal';
import RejectConcreteLoadModal from 'src/app-react/business-page/delivery-note-details/components/RejectConcreteLoadModal/RejectConcreteLoadModal';
import ReportArrivalModal from 'src/app-react/business-page/delivery-note-details/components/ReportArrivalModal/ReportArrivalModal';
import StartUnloadingModal from 'src/app-react/business-page/delivery-note-details/components/StartUnloadingModal/StartUnloadingModal';
import UploadDeliveryNoteModal from 'src/app-react/business-page/delivery-note-details/components/UploadDeliveryNoteModal/UploadDeliveryNoteModal';
import AcceptAndSignModal from 'src/app-react/business-page/delivery-note-details/components/accept-and-sign-delivery/AcceptAndSignModal';
import { DeliveryNoteProvider } from 'src/app-react/business-page/delivery-note-details/providers/delivery.note.provider';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModalForDocumentation/ConcreteTypeModal';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

export enum Modals {
    ConcreteTests,
    ReportArrival,
    StartUnloading,
    EndUnloading,
    AcceptAndSign,
    RejectConcreteLoad,
    ConcreteTypeModal,
    UploadDeliveryNote,
    DeliveryWaterModal,
    UploadDeliveryNoteModal
}

export interface ActionModalsProps {
    modal: Modals;
    setModal: (modal: Modals) => void;
    onClose: (keepSelected?: boolean) => void;
    onOk: () => void;
    selected: BasicConcreteLoadWithOrderId | undefined;
}

export function ActionModals({ modal, setModal, selected, onClose, onOk }: ActionModalsProps) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [supplierProducedConcrete, setSupplierProducedConcrete] = useState<SupplierProducedConcrete>();
    const [deliveryWaterOverview, setDeliveryWaterOverview] = useState<DeliveryWaterOverview>();

    const loadSupplierProducedConcrete = useCallback(() => {
        api.concretingDocumentationClient
            .getSupplierProducedConcreteBySupplierLoadId(companyId, projectId, selected.supplierConcreteLoadDataId)
            .then((response) => {
                if (response.isSuccess()) {
                    setSupplierProducedConcrete(response.getEntity());
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }, [selected]);

    useEffect(() => {
        if (selected && modal === Modals.DeliveryWaterModal) {
            api.deliveryNoteClient
                .getDeliveryWaterOverview(companyId, projectId, selected.id)
                .then((response) => {
                    if (response.isSuccess()) {
                        setDeliveryWaterOverview(response.getEntity());
                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }

        if (
            selected &&
            modal === Modals.ConcreteTypeModal &&
            supplierProducedConcrete?.number !== selected.concreteTypeNumber
        ) {
            loadSupplierProducedConcrete();
        } else {
            setSupplierProducedConcrete(null);
        }
    }, [selected, modal]);

    if (!selected) return <></>;

    return (
        <DeliveryNoteProvider projectId={projectId} orderId={selected.orderId}>
            <MultipleConcreteTestModal
                concreteLoadId={selected?.id}
                open={modal === Modals.ConcreteTests}
                setOpen={() => onClose()}
                onOkFreshConcreteTests={onOk}
                onOkTestSpecimen={onOk}
                initialFormValues={{
                    samplingDate: new Date(),
                    samplingTime: new Date()
                }}
                deliveryNoteNumber={selected?.deliveryNoteNumber}
            />

            <ReportArrivalModal
                concreteLoadId={selected?.id}
                licensePlate={selected?.truckLicensePlate}
                deliveryNoteNo={selected?.deliveryNoteNumber}
                open={modal === Modals.ReportArrival}
                setOpen={() => onClose()}
                onComplete={onOk}
            />

            <StartUnloadingModal
                concreteLoadId={selected?.id}
                licensePlate={selected?.truckLicensePlate}
                deliveryNoteNo={selected?.deliveryNoteNumber}
                arrivalDateTime={selected?.arrivalAtConstructionSite}
                processableUntil={selected?.processableUntil}
                open={modal === Modals.StartUnloading}
                setOpen={() => onClose()}
                onComplete={onOk}
            />

            <EndUnloadingModal
                concreteLoadId={selected?.id}
                licensePlate={selected?.truckLicensePlate}
                deliveryNoteNo={selected?.deliveryNoteNumber}
                startUnloadingDateTime={selected?.unloadingStart}
                open={modal === Modals.EndUnloading}
                setOpen={() => onClose(true)}
                setAcceptAndSignModalVisible={() => setModal(Modals.AcceptAndSign)}
                onComplete={onOk}
            />

            <AcceptAndSignModal
                projectId={projectId}
                orderId={selected?.orderId}
                concreteLoadId={selected?.id}
                open={modal === Modals.AcceptAndSign}
                setOpen={() => onClose()}
                fromDeliveryDetailsPage
                onComplete={onOk}
            />

            {modal === Modals.ConcreteTypeModal && !!supplierProducedConcrete && (
                <ConcreteTypeModal
                    concreteDetails={supplierProducedConcrete}
                    open={modal === Modals.ConcreteTypeModal}
                    setOpen={() => onClose()}
                    onClose={() => onClose()}
                />
            )}

            <RejectConcreteLoadModal
                deliveryNoteDetailsBP={
                    {
                        deliveryNoteData: {
                            dnId: selected?.id,
                            unloadingStart: selected?.unloadingStart,
                            unloadingEnd: selected?.unloadingEnd,
                            deliveryNoteNumber: selected?.deliveryNoteNumber,
                            truckLicensePlate: selected?.truckLicensePlate,
                            volume: selected?.volume
                        }
                    } as DeliveryNoteDetailsBP
                }
                open={modal === Modals.RejectConcreteLoad}
                setOpen={() => onClose()}
                onComplete={onOk}
            />
            <UploadDeliveryNoteModal
                concreteLoadId={selected.id}
                // open={modal === Modals.UploadDeliveryNote}
                open={false}
                setOpen={() => onClose()}
            />

            <DeliveryWaterModal
                concreteLoadId={selected?.id}
                deliveryWaterOverview={deliveryWaterOverview}
                deliveryNumber={selected?.deliveryNoteNumber}
                truckNumber={selected?.truckLicensePlate}
                open={modal === Modals.DeliveryWaterModal}
                setOpen={() => onClose()}
            />

            <UploadDeliveryNoteModal
                concreteLoadId={selected?.id}
                open={modal === Modals.UploadDeliveryNoteModal}
                setOpen={() => onClose()}
                onComplete={onOk}
            />
        </DeliveryNoteProvider>
    );
}
