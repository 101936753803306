import { DeleteOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { DeliveryWater } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { IMarketUnits } from 'src/app-react/constants/marketsData';

type FixedType = boolean | 'right' | 'left';

export const deliveryWaterColumns = ({
    units,
    onDelete
}: {
    units: IMarketUnits;
    onDelete: (record: DeliveryWater) => void;
}) => {
    return [
        {
            title: <Trans>Water added on site</Trans>,
            key: 'water_quantity',
            dataIndex: 'water_quantity',
            render: (_: string, record: DeliveryWater) => (
                <NumberFormatter value={record.waterQuantity} unit={units.water} />
            )
        },
        {
            title: <Trans>Addition time</Trans>,
            key: 'addition_time',
            dataIndex: 'addition_time',
            width: 150,
            sorter: true,
            render: (_: string, record: DeliveryWater) => (
                <span className="deliveryStatusDate">
                    <DateTimeFormatter date={record.additionTime} />
                </span>
            )
        },
        {
            title: <Trans>Comments</Trans>,
            key: 'comments',
            dataIndex: 'comments',
            render: (_: string, record: DeliveryWater) => <span>{record.comments}</span>
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            fixed: 'right' as FixedType,
            width: 200,
            render: (_: string, record: DeliveryWater) => (
                <Space direction="horizontal">
                    <Button data-testid="deleteButton" type="link" size="small" onClick={() => onDelete(record)}>
                        <DeleteOutlined /> <Trans>Delete</Trans>
                    </Button>
                </Space>
            )
        }
    ] as ColumnsType<DeliveryWater>;
};

export default deliveryWaterColumns;
