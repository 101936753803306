/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

import { Trans } from '@lingui/macro';
import { Divider, Table } from 'antd';
import styles from '../shared/shared.module.css';
import { deliveryDetailsRejectedTableColumns, deliveryDetailsTableColumns } from './delivery-details-table.columns';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryNoteAllDetails } from '../../hooks/useDeliveryNoteDetails';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import { keyMapFromTableData } from '../../util';

export interface DetailsTable {
    projectId?: string;
    deliveryAllDetails: DeliveryNoteAllDetails;
    reloadData?: (value: boolean) => void;
    setUploadDeliveryNoteModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    deleteFileClicked?: (d: DeliveryNoteAllDetails) => void;
    downloadAttachmentClicked?: (d: DeliveryNoteAllDetails) => void;
}

function DeliveryDetailsTable({
    deliveryAllDetails,
    reloadData,
    setUploadDeliveryNoteModalVisible,
    deleteFileClicked,
    downloadAttachmentClicked
  }: DetailsTable) {
    const { marketId, supplierConfig } = useProjectState();
    const { units, formats } = useProjectConstantsState();

    const isRejectedConcreteLoad = !!deliveryAllDetails.concreteLoad.rejectedOn;

    return !deliveryAllDetails ? null : (
        <>
            {isRejectedConcreteLoad ? (
                <>
                    <span className={styles.tableTitle}>
                        <Trans>Load rejected</Trans>
                    </span>
                    <br />
                    <br />
                    <Table
                        bordered={false}
                        data-testid="delivery-details-rejected-table"
                        scroll={{ x: 1 }}
                        columns={deliveryDetailsRejectedTableColumns()}
                        dataSource={keyMapFromTableData([deliveryAllDetails])}
                        pagination={false}
                        rowClassName="delivery-details-rejected-row"
                    />
                    <br />
                </>
            ) : null}

            <p className={styles.tableTitle}>
                <Trans>Delivery information</Trans>
            </p>
            <Table
                bordered={false}
                data-testid="delivery-details-table"
                scroll={{ x: 1 }}
                columns={deliveryDetailsTableColumns({
                    marketId: marketId as MARKET_VALUES,
                    units,
                    isQr: supplierConfig?.isQrType,
                    dateTimeFormat: formats.dateTime,
                    setUploadDeliveryNoteModalVisible,
                    deleteFileClicked,
                    downloadAttachmentClicked
                })}
                dataSource={keyMapFromTableData([deliveryAllDetails])}
                pagination={false}
                rowClassName="delivery-details-row"
            />
            <Divider />
        </>
    );
}

export default DeliveryDetailsTable;
