/* eslint-disable react/jsx-no-undef */
import { Col, Row } from 'antd';
import MaxAggregateSizeFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MaxAggregateSizeFormItem';
import CompressiveStrengthFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/CompressiveStrengthFormItem';
import MaxWaterToAddFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/MaxWaterToAddFormItem';
import SlumpFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/SlumpFormItem';
import {
    ORDER_LOAD_LITE_TYPES,
    OrderLoadLiteTypesValues
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';

interface OrderLiteModalForm2DefaultAUSLayoutProps {
    type?: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}
export default function OrderLiteModalForm2DefaultAUSLayout({
    type,
    origin
}: OrderLiteModalForm2DefaultAUSLayoutProps) {
    const compressiveStrengthFormItemRules =
        type === ORDER_LOAD_LITE_TYPES.QR || origin === ORDER_LOAD_LITE_TYPES.QR.toLowerCase()
            ? [numberRule({ min: 0, decimal: false })]
            : [numberRule({ minOrEqual: 0, decimal: false })];

    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <CompressiveStrengthFormItem required={false} rules={compressiveStrengthFormItemRules} />
                </Col>
                <Col span="12">
                    <SlumpFormItem required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MaxWaterToAddFormItem required={false} />
                </Col>
                <Col span="12">
                    <MaxAggregateSizeFormItem />
                </Col>
            </Row>
        </>
    );
}
