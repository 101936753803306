import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { DeliveryWaterOverview } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Form, Modal } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import units from 'src/app-react/constants/marketsData/marketDataAUS/dataValues/units';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { parseDecimalByLocale } from 'src/app-react/utils/formatters';
import { mergeDateTime } from 'src/app-react/utils/lib';
import styles from './DeliveryWaterModal.module.css';
import LayoutConfirmation from './Layouts/LayoutConfirmation';
import LayoutDefault from './Layouts/LayoutDefault';

interface DeliveryWaterModalProps {
    concreteLoadId: string;
    deliveryNumber: string;
    truckNumber: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deliveryWaterOverview?: DeliveryWaterOverview;
    onComplete?: () => void;
}

enum DeliveryWaterModalLayoutTypes {
    Default,
    Confirmation
}

export default function DeliveryWaterModal({
    concreteLoadId,
    deliveryWaterOverview,
    deliveryNumber,
    truckNumber,
    open,
    setOpen,
    onComplete
}: DeliveryWaterModalProps) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [deliveryOverview, setDeliveryWaterOverview] = useState<DeliveryWaterOverview | undefined>(
        deliveryWaterOverview
    );

    const [layout, setLayout] = useState<DeliveryWaterModalLayoutTypes>(DeliveryWaterModalLayoutTypes.Default);

    const [form] = Form.useForm();
    const waterValue = Form.useWatch('waterQuantity', form);

    const resetLayout = async () => {
        setTimeout(() => {
            setLayout(DeliveryWaterModalLayoutTypes.Default);
        }, 200);
    };

    const maxWaterExceedWarningVisible = useCallback(() => {
        if (!waterValue || !deliveryWaterOverview?.remainignWaterAddition) {
            return false;
        }

        return Number(waterValue) > deliveryWaterOverview.remainignWaterAddition;
    }, [waterValue, deliveryWaterOverview?.remainignWaterAddition]);

    const loadDeliveryWaterOverview = useCallback(async () => {
        api.deliveryNoteClient
            .getDeliveryWaterOverview(companyId, projectId, concreteLoadId)
            .then((response) => {
                if (response.isSuccess()) {
                    setDeliveryWaterOverview(response.getEntity());
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }, [api.deliveryNoteClient, companyId, projectId, concreteLoadId]);

    useEffect(() => {
        if (!deliveryOverview) {
            loadDeliveryWaterOverview();
        }
    }, [projectId, companyId, api.deliveryNoteClient, concreteLoadId, loadDeliveryWaterOverview, deliveryOverview]);

    const onSubmit = async (): Promise<void> => {
        const { waterQuantity, date, time, comments } = await form.validateFields();

        if (maxWaterExceedWarningVisible() && layout === DeliveryWaterModalLayoutTypes.Default) {
            setLayout(DeliveryWaterModalLayoutTypes.Confirmation);
            return;
        }

        api.deliveryNoteClient
            .createDeliveryWater(companyId, projectId, concreteLoadId, {
                waterQuantity: parseDecimalByLocale(waterQuantity, i18n.locale),
                waterQuantityUnit: units.water.toUpperCase(),
                additionTime: mergeDateTime(date, time).toDate(),
                comments
            })
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: 'Delivery water created',
                        description: ''
                    });
                    form.resetFields();
                    setOpen(false);
                    resetLayout();
                    onComplete?.();
                }
            })
            .catch((err) => {
                ErrorNotification({
                    message: err.message,
                    description: ''
                });
                resetLayout();
            });
    };

    const onCancel = (): void => {
        form.resetFields();
        setOpen(false);
        resetLayout();
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="create-deliverywater-modal"
            forceRender
            getContainer={false}
            title={t`Add delivery water`}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button data-testid="cancel-btn" key="cancelButton" onClick={onCancel}>
                    <Trans>CANCEL</Trans>
                </Button>,
                <Button
                    className={styles.modalButton}
                    onClick={onSubmit}
                    data-testid="submit-btn"
                    type="primary"
                    key={`submit-btn-${concreteLoadId}`}>
                    <Trans>SAVE</Trans>
                </Button>
            ]}>
            <Form
                form={form}
                data-testid="delivery-water-form"
                key={`modal-form-${concreteLoadId}`}
                layout="vertical"
                initialValues={{ date: moment(), time: moment(), comment: '' }}>
                <div hidden={layout !== DeliveryWaterModalLayoutTypes.Default}>
                    <LayoutDefault
                        concreteLoadId={concreteLoadId}
                        deliveryNumber={deliveryNumber}
                        truckNumber={truckNumber}
                        deliveryWaterOverview={deliveryOverview}
                        maxWaterExceedWarningVisible={maxWaterExceedWarningVisible()}
                    />
                </div>
                <div hidden={layout !== DeliveryWaterModalLayoutTypes.Confirmation}>
                    <LayoutConfirmation />
                </div>
            </Form>
        </Modal>
    );
}
