import { IMarketUnits } from '../..';

const UNITS: IMarketUnits = {
    volume: 'm3',
    water: 'L',
    density: 'kg/m3',
    temperature: '°C',
    compressiveStrength: 'MPa',
    slump: 'mm',
    maxAggregateSize: 'mm'
};

export default UNITS;
