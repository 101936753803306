import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import { IMarketUnits } from 'src/app-react/constants/marketsData';

export const concretingInformationTableColumns = ({ units }: { units: IMarketUnits }) => {
    return [
        {
            title: <Trans>Mix code</Trans>,
            key: 'concrete_number',
            dataIndex: 'concrete_number',
            render: (_: string, record: DeliveryNoteAllDetails) => <p>{record.supplierProducedConcrete?.number} </p>
        },
        {
            title: <Trans>Maximum Aggregate size</Trans>,
            key: 'max_aggregate_size',
            dataIndex: 'max_aggregate_size',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <NumberFormatter
                    value={record.supplierProducedConcrete?.maxAggregationSize}
                    unit={units.maxAggregateSize}
                />
            )
        },
        {
            title: <Trans>Slump</Trans>,
            key: 'slump',
            dataIndex: 'slump',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <NumberFormatter value={record.supplierProducedConcrete?.slump} unit={units.slump} />
            )
        },
        {
            title: <Trans>Compressive strength</Trans>,
            key: 'compressive_strength',
            dataIndex: 'compressive_strength',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <NumberFormatter
                    value={record.supplierProducedConcrete?.compressiveStrength}
                    unit={units.compressiveStrength}
                />
            )
        },
        {
            title: <Trans>Evaporation rate</Trans>,
            key: 'evaporation_rate',
            dataIndex: 'evaporation_rate',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <NumberFormatter
                    value={record?.evaporationRate?.evaporationRate}
                    unit={record?.evaporationRate?.evaporationRateUnit}
                    format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                />
            )
        }
    ] as ColumnsType<DeliveryNoteAllDetails>;
};

export default concretingInformationTableColumns;
