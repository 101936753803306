/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import {
    DeliveryStatus,
    DeliveryWater
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Breadcrumb, Button } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import OrderLiteLoadModal from 'src/app-react/business-page/shared/Modals/OrderLiteModal';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useModalState from 'src/app-react/hooks/useModalState';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import ConcreteTestsTabs from '../delivery-note-details/components/ConcreteTestsTabs/ConcreteTestsTabs';
import UploadDeliveryNoteModal from '../delivery-note-details/components/UploadDeliveryNoteModal/UploadDeliveryNoteModal';
import DeliveryNoteDetailsContext from '../delivery-note-details/context/delivery-note-details-context';
import { DeliveryNoteManagementContext } from '../delivery-note-details/providers/delivery.note.provider';
import { CONCRETE_LOAD_LITE_MODAL_ACTION } from '../shared/Modals/OrderLiteModal/models/Types';
import CommentConcreteLoadList from './components/comment-concrete-load-list/comment-concrete-load-list';
import ConcreteInformationDataTable from './components/concrete-information-data-table/concrete-information-data-table';
import ConcreteLoadIngredientsOverviewTable from './components/concrete-load-ingredients-overview-table/concrete-load-ingredients-overview-table';
import ConcreteDetailsTable from './components/ConcretingDetailsTable/ConcreteDetailsTable';
import CustomerInformationTable from './components/CustomerInformationTable';
import DeliveryAdmixtureModal from './components/delivery-admixture-modal/delivery-admixture-modal';
import DeliveryAdmixtureTable from './components/delivery-admixture-table/delivery-admixture-table';
import DeliveryDetailsTable from './components/delivery-details-table/delivery-details-table';
import DeliveryWaterDeleteModal from './components/delivery-water-modal/DeliveryWaterDeleteModal';
import DeliveryWaterModal from './components/delivery-water-modal/DeliveryWaterModal';
import DeliveryWaterTable from './components/delivery-water-table/delivery-water-table';
import { DeliveryTimestampsTable } from './components/DeliveryTimestampsTable/DeliveryTimestampsTable';
import OrderFormInformationTable from './components/OrderFormInformation';
import { StatusButton } from './components/status-button/StatusButton';
import SupplierInformationTable from './components/SupplierInformationTable';
import styles from './delivery.details.page.module.css';
import useDeliveryNoteDetails, { DeliveryNoteAllDetails } from './hooks/useDeliveryNoteDetails';

interface DeliveryDetails {
    orderId: string;
    concreteLoadId: string;
}

function editButtonDisabled(status?: DeliveryStatus) {
    return status === 'Rejected' || status === 'Signed';
}

function DeliveryDetailsPage({ orderId, concreteLoadId }: DeliveryDetails) {
    const api = useApi();
    const { units } = useProjectConstantsState();
    const { projectId, marketId, companyId, supplierConfig } = useProjectState();
    const { reloaded, reloadDeliveryNotePage } = useContext(DeliveryNoteManagementContext);
    const orderLiteModalState = useModalState();
    const [admixtureModal, setAdmixtureModal] = useState(false);
    const [deliveryWaterModalVisible, setDeliveryWaterModalVisible] = useState(false);
    const [isConcreteTestModalVisible, setIsConcreteTestModalVisible] = useState(false);
    const [uploadDeliveryNoteModalVisible, setUploadDeliveryNoteModalVisible] = useState(false);

    const { isAllowToEditConcreteDeliveryNotes, isAllowToCreateAdmixture, isAllowToCreateConcreteTests } =
        useUserPermissions();

    const {
        deliveryDetails,
        deliveryFullDetails,
        concreteLoadDataLite,
        deliveryAllDetails,
        concreteLoadIngredients,
        tests,
        loadDetails
    } = useDeliveryNoteDetails({
        orderId,
        concreteLoadId
    });

    const [reloadData, setReloadData] = useState(false);

    const navigate: NavigateFunction = useNavigate();

    const contextValue = useMemo(
        () => ({
            projectId,
            orderId,
            concreteLoadId
        }),
        [projectId, orderId, concreteLoadId]
    );

    useEffect(() => {
        setReloadData(false);
        loadDetails();
    }, [projectId, orderId, concreteLoadId, reloadData, companyId, loadDetails, reloaded]);

    const now: Date = new Date();
    const initialFormValuesConcreteTestModalConst = {
        samplingDate: now,
        samplingTime: now
    };
    const [initialFormValuesConcreteTestModal, setInitialFormValuesConcreteTestModal] = useState(
        initialFormValuesConcreteTestModalConst
    );

    function resetInitialFormValuesConcreteTestModal(): void {
        setInitialFormValuesConcreteTestModal(initialFormValuesConcreteTestModalConst);
    }

    function concreteTestButtonOnClick(): void {
        resetInitialFormValuesConcreteTestModal();
        setIsConcreteTestModalVisible(true);
    }

    function deleteFileClicked(delivery: DeliveryNoteAllDetails) {
        api.deliveryNoteClient
            .deliveryNoteDeleteFile(companyId, projectId, delivery.concreteLoad.id)
            .then(() => {
                SuccessNotification({
                    message: 'File deleted'
                });
                reloadDeliveryNotePage();
            })
            .catch(() => {
                ErrorNotification({
                    message: 'Error deleting file',
                    description: ''
                });
            });
    }

    const onDeleteDeliveryWater = (deliveryWater: DeliveryWater) => {
        return DeliveryWaterDeleteModal({
            api,
            companyId,
            projectId,
            deliveryWater,
            onSuccess: () => reloadDeliveryNotePage()
        });
    };

    return (
        deliveryAllDetails && (
            <>
                <AppHeader
                    title={t`Delivery details`}
                    onBack={() => navigate(-1)}
                    breadcrumb={
                        <ProjectBreadcrumb>
                            <Breadcrumb.Item>
                                <Trans>Order monitoring</Trans>
                            </Breadcrumb.Item>
                        </ProjectBreadcrumb>
                    }
                    extra={
                        <>
                            <Button
                                data-testid="edit-btn"
                                type="primary"
                                size="middle"
                                hidden={supplierConfig.isApiType || isAllowToEditConcreteDeliveryNotes === false}
                                disabled={editButtonDisabled(
                                    deliveryDetails?.deliveryNoteData?.status as DeliveryStatus
                                )}
                                block
                                onClick={orderLiteModalState.open}>
                                <PlusOutlined /> <Trans>Edit</Trans>
                            </Button>
                            <Button
                                data-testid="test-btn"
                                hidden={!isAllowToCreateConcreteTests}
                                icon={<PlusOutlined />}
                                onClick={() => concreteTestButtonOnClick()}>
                                {' '}
                                <Trans>record concrete test</Trans>
                            </Button>
                            <Button
                                data-testid="admixture-btn"
                                type="default"
                                hidden={!isAllowToCreateAdmixture}
                                onClick={() => setAdmixtureModal(true)}>
                                <PlusOutlined /> <Trans>Add admixture</Trans>
                            </Button>
                            {marketId === MARKETS.AUS && (
                                <Button
                                    data-testid="add-delivery-water-btn"
                                    type="default"
                                    hidden={!isAllowToEditConcreteDeliveryNotes}
                                    onClick={() => setDeliveryWaterModalVisible(true)}>
                                    <PlusOutlined /> <Trans>Add water on site</Trans>
                                </Button>
                            )}
                            <StatusButton
                                concreteLoadId={concreteLoadId}
                                deliveryDetails={deliveryDetails}
                                loadDetails={loadDetails}
                            />
                        </>
                    }
                />

                <DeliveryNoteDetailsContext.Provider value={contextValue}>
                    <DeliveryDetailsTable
                        projectId={projectId}
                        deliveryAllDetails={deliveryAllDetails}
                        reloadData={setReloadData}
                        setUploadDeliveryNoteModalVisible={setUploadDeliveryNoteModalVisible}
                        deleteFileClicked={deleteFileClicked}
                    />

                    <DeliveryTimestampsTable deliveryAllDetails={deliveryAllDetails} reloadData={setReloadData} />
                    {marketId === MARKETS.AUS && (
                        <>
                            <OrderFormInformationTable deliveryDetails={deliveryFullDetails} />
                            <SupplierInformationTable deliveryDetails={deliveryFullDetails} />
                            <CustomerInformationTable deliveryDetails={deliveryFullDetails} />
                        </>
                    )}

                    <ConcreteInformationDataTable deliveryNoteAllDetails={deliveryAllDetails} />
                    {marketId === MARKETS.AUS && (
                        <DeliveryWaterTable
                            onDelete={onDeleteDeliveryWater}
                            deliveryWater={deliveryAllDetails.concreteLoad.deliveryWater}
                            deliveryWaterOverview={deliveryAllDetails.concreteLoad.deliveryWaterOverview}
                            maxWaterOnSite={deliveryFullDetails.supplierProducedConcrete.maxWaterToAdd}
                        />
                    )}
                    <ConcreteLoadIngredientsOverviewTable concreteLoadIngredients={concreteLoadIngredients} />

                    <ConcreteDetailsTable deliveryNoteAllDetails={deliveryAllDetails} />
                    <DeliveryAdmixtureTable concreteAdmixture={deliveryDetails.admixtures} />
                    <CommentConcreteLoadList commentsConceteLoad={deliveryDetails.commentsConcreteLoad} />

                    <div className={styles.statusButtonBar}>
                        <ConcreteTestsTabs
                            orderId={orderId}
                            concreteLoadId={concreteLoadId}
                            deliveryNoteNumber={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                            isConcreteTestModalVisible={isConcreteTestModalVisible}
                            setIsConcreteTestModalVisible={setIsConcreteTestModalVisible}
                            initialFormValuesConcreteTestModal={initialFormValuesConcreteTestModal}
                            resetInitialFormValuesConcreteTestModal={resetInitialFormValuesConcreteTestModal}
                            testRecords={tests}
                        />
                        <DeliveryAdmixtureModal
                            orderId={orderId}
                            deliveryNoteId={concreteLoadId}
                            deliveryNoteNo={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                            licensePlate={deliveryDetails.deliveryNoteData.truckLicensePlate}
                            onComplete={() => loadDetails()}
                            viewModal={admixtureModal}
                            setViewModal={setAdmixtureModal}
                        />
                        {marketId === MARKETS.AUS && (
                            <DeliveryWaterModal
                                concreteLoadId={concreteLoadId}
                                deliveryWaterOverview={deliveryAllDetails.concreteLoad.deliveryWaterOverview}
                                deliveryNumber={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                                truckNumber={deliveryDetails.deliveryNoteData.truckLicensePlate}
                                onComplete={() => loadDetails()}
                                open={deliveryWaterModalVisible}
                                setOpen={setDeliveryWaterModalVisible}
                            />
                        )}
                    </div>

                    {orderLiteModalState.isOpen && (
                        <OrderLiteLoadModal
                            action={CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT}
                            open={orderLiteModalState.isOpen}
                            close={() => {
                                orderLiteModalState.close();
                                setReloadData(true);
                            }}
                            concreteLoadDataLite={concreteLoadDataLite}
                            concreteLoadId={concreteLoadId}
                        />
                    )}
                    <UploadDeliveryNoteModal
                        concreteLoadId={concreteLoadId}
                        open={uploadDeliveryNoteModalVisible}
                        setOpen={setUploadDeliveryNoteModalVisible}
                        onComplete={() => setReloadData(true)}
                    />
                </DeliveryNoteDetailsContext.Provider>
            </>
        )
    );
}

export default DeliveryDetailsPage;
