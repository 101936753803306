import { t, Trans } from '@lingui/macro';
import { DeliveryWaterOverview } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Alert, Card, Col, DatePicker, Form, Input, Row, Spin, TimePicker } from 'antd';
import moment from 'moment';
import AmountCol from 'src/app-react/business-page/delivery-note-details/components/AmountCol';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import units from 'src/app-react/constants/marketsData/marketDataAUS/dataValues/units';

interface LayoutDefaultProps {
    concreteLoadId: string;
    deliveryNumber: string;
    truckNumber: string;
    deliveryWaterOverview?: DeliveryWaterOverview;
    maxWaterExceedWarningVisible: boolean;
}
export default function LayoutDefault({
    concreteLoadId,
    deliveryNumber,
    truckNumber,
    deliveryWaterOverview,
    maxWaterExceedWarningVisible
}: LayoutDefaultProps) {
    return (
        <>
            {deliveryWaterOverview ? (
                <Card data-testid="water-on-site-amount-card-default" style={{ backgroundColor: '#E7EDF5' }}>
                    <Row>
                        <AmountCol title={t`Delivery docket number`}>{deliveryNumber}</AmountCol>
                        <AmountCol title={t`Truck number`}>{truckNumber}</AmountCol>
                    </Row>
                    <Row>
                        <AmountCol title={t`Max. water to add`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview.maxWaterToAdd === 0
                                        ? '-'
                                        : deliveryWaterOverview.maxWaterToAdd
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                        <AmountCol title={t`Total water added on site`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview.totalWaterOnSite === 0
                                        ? '-'
                                        : deliveryWaterOverview.totalWaterOnSite
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                        <AmountCol title={t`Remaning water for addition`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview.remainignWaterAddition === 0
                                        ? '-'
                                        : deliveryWaterOverview.remainignWaterAddition
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                    </Row>
                </Card>
            ) : (
                <Card
                    data-testid="water-on-site-amount-card-default"
                    style={{
                        backgroundColor: '#E7EDF5',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <Spin style={{ display: 'block', marginBottom: '1rem' }} />
                    <Trans>Loading delivery water overview</Trans>
                </Card>
            )}

            <p style={{ marginTop: '1rem' }}>
                <Trans>Please, enter the time when the truck arrived at the construction site</Trans>
            </p>

            <Row gutter={8}>
                <Col span="12">
                    <Form.Item
                        name="waterQuantity"
                        style={{ fontWeight: 'bold' }}
                        label={<Trans>Added water on site</Trans>}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^\d+$/),
                                message: t`This field is required`
                            }
                        ]}>
                        <Input
                            type="number"
                            data-testid="waterQuantity"
                            addonAfter={units.water}
                            placeholder={t`Enter the water quantity`}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span="12">
                    <Form.Item
                        label={<Trans>Addition Date</Trans>}
                        style={{ fontWeight: 'bold' }}
                        name="date"
                        rules={[{ required: true, message: t`Please input date` }]}>
                        <DatePicker
                            data-testid="date-input"
                            style={{ width: '90%' }}
                            value={moment()}
                            placeholder={t`Enter Date`}
                        />
                    </Form.Item>
                </Col>
                <Col span="12">
                    <Form.Item
                        label={<Trans>Addition Time</Trans>}
                        style={{ fontWeight: 'bold', flexDirection: 'column' }}
                        name="time"
                        rules={[{ required: true, message: t`Please input time` }]}>
                        <TimePicker value={moment()} style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                {maxWaterExceedWarningVisible && (
                    <Alert
                        type="warning"
                        showIcon
                        description={t`By adding that amount of water you will be exceeding the preestablished Max. water to add value.`}
                    />
                )}
            </Row>
            <Row gutter={8}>
                <Col span="24">
                    <Form.Item name="comments" style={{ fontWeight: 'bold' }} label={<Trans>Comment</Trans>}>
                        <Input.TextArea
                            data-testid="comment-input"
                            placeholder={t`Enter comments`}
                            autoSize={{ minRows: 5, maxRows: 20 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
