import { t, Trans } from '@lingui/macro';
import { Alert, Row, Space } from 'antd';
import styles from '../DeliveryWaterModal.module.css';

export default function LayoutConfirmation() {
    return (
        <>
            <Row>
                <Alert
                    className={styles.layoutConfirmation}
                    type="warning"
                    showIcon
                    description={t`Are you sure to save this water addition record?`}
                />
            </Row>
            <Row style={{ paddingLeft: '64px' }}>
                <Trans>
                    By adding that amount of water you will be exceeding the preestablished Max. water to add value.
                </Trans>
            </Row>
        </>
    );
}
