import {
    DeliveryNoteBP,
    DeliveryNoteDetailsBP,
    DeliveryNotesWithPaginationParams
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Filtering } from '@nexploretechnology/concreting-core-client/url.builder';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { defaultPagination, pageSize, paginationAdapterReactAntdToBCQD } from 'src/app-react/utils/pagination';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import EndUnloadingModal from '../EndUnloadingModal/EndUnloadingModal';
import MultipleConcreteTestModal from '../MultipleConcreteTestModal/MultipleConcreteTestModal';
import RejectConcreteLoadModal from '../RejectConcreteLoadModal/RejectConcreteLoadModal';
import ReportArrivalModal from '../ReportArrivalModal/ReportArrivalModal';
import StartUnloadingModal from '../StartUnloadingModal/StartUnloadingModal';
import AcceptAndSignModal from '../accept-and-sign-delivery/AcceptAndSignModal';
import DeliveryStatusStep from '../delivery-status-step/delivery-status-step';
import { StatusTableColumns, deliveryStatusTableColumns } from './delivery-status-table.columns';
import UploadDeliveryNoteModal from '../UploadDeliveryNoteModal/UploadDeliveryNoteModal';

interface StatusTable {
    status: string;
    refreshTable: boolean;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}

function DeliveryStatusTable(props: StatusTable) {
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { projectId, marketId, companyId } = useProjectState();
    const permissions = useUserPermissions();
    const navigate: NavigateFunction = useNavigate();
    const api = useApi();

    const { status, refreshTable, setRefreshTable, count, setCount } = props;
    const [deliveryDetails, setDeliveryDetails] = useState<DeliveryNoteDetailsBP>(null);
    const [tableData, setTableData] = useState([] as DeliveryNoteBP[]);
    const [concreteLoadId, setConcreteLoadId] = useState<string>();
    const [deliveryNoteNo, setDeliveryNoteNo] = useState<string>();
    const [licensePlate, setLicensePlate] = useState<string>();
    const [processableUntil, setProcessableUntil] = useState(new Date());
    const [arrivalDateTime, setArrivalDateTime] = useState(new Date());
    const [startUnloadingDateTime, setStartUnloadingDateTime] = useState(new Date());

    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [unloadingModalVisible, setUnloadingModalVisible] = useState(false);
    const [unloadedModalVisible, setUnloadedModalVisible] = useState(false);
    const [rejectConcreteLoadModalVisible, setRejectConcreteLoadModalVisible] = useState(false);
    const [acceptAndSignModalVisible, setAcceptAndSignModalVisible] = useState(false);
    const [uploadDeliveryNoteModalVisible, setUploadDeliveryNoteModalVisible] = useState(false);

    const [pagination, setPagination] = useState<OffsetPagination<DeliveryNoteBP>>(defaultPagination);

    const [isConcreteTestModalVisible, setIsConcreteTestModalVisible] = useState(false);

    const [filterDeliveryNoteNumber, setFilterDeliveryNoteNumber] = useState('');
    const filterDeliveryNoteNumberValue = useRef('');
    const filterDeliveryNoteNumberClicked = async () => {
        filterDeliveryNoteNumberValue.current = filterDeliveryNoteNumber;
        getDeliveriesByStatus();
    };

    const [filterTruckLicensePlate, setFilterTruckLicensePlate] = useState('');
    const filterTruckLicensePlateValue = useRef('');
    const filterTruckLicensePlateClicked = async () => {
        filterTruckLicensePlateValue.current = filterTruckLicensePlate;
        getDeliveriesByStatus();
    };

    const requestDetails = async (dnId: string): Promise<void> => {
        try {
            if (!projectId || !companyId) {
                return;
            }
            await api.deliveryNoteClient
                .getDeliveryNoteDetails(projectId, orderId, dnId, companyId)
                .then((response: SimpleClientResponse<DeliveryNoteDetailsBP>) => {
                    if (response.isSuccess()) {
                        const detailsReq: DeliveryNoteDetailsBP = response.getEntity();
                        setDeliveryDetails(detailsReq);
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const now: Date = new Date();
    const _initialFormValuesConcreteTestModal = {
        samplingDate: now,
        samplingTime: now
    };
    const [initialFormValuesConcreteTestModal, setInitialFormValuesConcreteTestModal] = useState(
        _initialFormValuesConcreteTestModal
    );

    function resetInitialFormValuesConcreteTestModal(): void {
        setInitialFormValuesConcreteTestModal(_initialFormValuesConcreteTestModal);
    }

    const getDeliveriesByStatus = () => {
        if (!projectId || !companyId) {
            return;
        }
        const filtering: Filtering[] = [
            { name: 'deliveryNoteNumber', value: filterDeliveryNoteNumberValue.current || '' },
            { name: 'truckLicensePlate', value: filterTruckLicensePlateValue.current || '' }
        ];

        api.deliveryNoteClient
            .getDeliveryNotesByStatusAsDataList(
                projectId,
                orderId,
                companyId,
                status,
                { pagination } as unknown as DeliveryNotesWithPaginationParams,
                filtering
            )
            .then(async (response) => {
                if (response.isSuccess()) {
                    const responseDeliveries = response.getEntity();
                    setTableData(responseDeliveries.data);
                    setCount(responseDeliveries.count);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info.errorFields ? info.errorFields[0]?.errors[0] : info.response?.data?.message,
                    description: ''
                });
            });
    };

    useEffect(() => {
        // first load
        getDeliveriesByStatus();

        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            getDeliveriesByStatus();
        }, 20000);

        return () => clearInterval(interval);
    }, [status, projectId, companyId, orderId, pagination]);

    useEffect(() => {
        if (refreshTable) {
            getDeliveriesByStatus();
            setRefreshTable(false);
        }
    }, [refreshTable]);

    const keyMapFromTableData = (tableData: DeliveryNoteBP[]) => {
        return tableData.map((value: DeliveryNoteBP, key: number) => {
            return { ...value, key };
        });
    };

    const displayConcreteTestModal = (dnId: string, dnNo: string) => {
        setConcreteLoadId(dnId);
        setDeliveryNoteNo(dnNo);
        resetInitialFormValuesConcreteTestModal();
        setIsConcreteTestModalVisible(true);
    };

    const displayRejectConcreteLoadModal = (dnId: string) => {
        setConcreteLoadId(dnId);
        requestDetails(dnId);
        setRejectConcreteLoadModalVisible(true);
    };

    const displayReportArrivalModal = (dnId: string, dnNo: string, licensePlate: string) => {
        setConcreteLoadId(dnId);
        setDeliveryNoteNo(dnNo);
        setLicensePlate(licensePlate);
        setReportModalVisible(true);
    };

    const displayStartUnloadingModal = (
        dnId: string,
        dnNo: string,
        licensePlate: string,
        arrivalDateTime: Date,
        processableUntil: Date
    ) => {
        setConcreteLoadId(dnId);
        setDeliveryNoteNo(dnNo);
        setLicensePlate(licensePlate);
        setProcessableUntil(processableUntil);
        setArrivalDateTime(arrivalDateTime);
        setUnloadingModalVisible(true);
    };

    const displayEndUnloadingModal = (
        dnId: string,
        dnNo: string,
        licensePlate: string,
        startUnloadingDateTime: Date
    ) => {
        setConcreteLoadId(dnId);
        setDeliveryNoteNo(dnNo);
        setLicensePlate(licensePlate);
        setStartUnloadingDateTime(startUnloadingDateTime);
        setUnloadedModalVisible(true);
    };

    const displayAcceptAndSignModal = (dnId: string) => {
        setConcreteLoadId(dnId);
        setAcceptAndSignModalVisible(true);
    };

    const displayUploadDeliveryModal = (dnId: string) => {
        setConcreteLoadId(dnId);
        setUploadDeliveryNoteModalVisible(true);
    };

    const openDeliveryDetailsPage = (dnId: string) => {
        navigate(`/projects/${projectId}/orders/${orderId}/delivery-notes-details/${dnId}/details`);
    };

    function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<DeliveryNoteBP> | SorterResult<DeliveryNoteBP>[],
        extra: TableCurrentDataSource<DeliveryNoteBP>
    ): void {
        setPagination(paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra));
    }

    async function downloadAttachmentClicked(record: DeliveryNoteBP) {
        const fileResponse = await api.deliveryNoteClient.deliveryNoteGetFile(companyId, projectId, record.dnId);
        if (fileResponse.isSuccess()) {
            const { uri } = fileResponse.getEntity();
            window.open(uri, '_blank');
        }
    }

    const tableColumnsProps: StatusTableColumns = {
        displayRejectConcreteLoadModal,
        displayConcreteTestModal,
        displayReportArrivalModal,
        displayStartUnloadingModal,
        displayEndUnloadingModal,
        displayAcceptAndSignModal,
        displayUploadDeliveryModal,
        openDeliveryDetailsPage,
        projectId,
        marketId,
        filterDeliveryNoteNumber,
        setFilterDeliveryNoteNumber,
        filterDeliveryNoteNumberClicked,
        filterTruckLicensePlate,
        setFilterTruckLicensePlate,
        filterTruckLicensePlateClicked,
        permissions,
        downloadAttachmentClicked
    };

    return (
        <>
            <Table
                data-testid="delivery-status-table"
                scroll={{ x: 10 }}
                columns={deliveryStatusTableColumns(tableColumnsProps)}
                expandable={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandedRowRender: (record) => <DeliveryStatusStep status={record.deliveryStatus} />
                }}
                dataSource={keyMapFromTableData(tableData)}
                onChange={onChange}
                pagination={{
                    position: ['bottomRight'],
                    defaultPageSize: pageSize,
                    total: count,
                    current: pagination.skip / pagination.limit + 1
                }}
                rowClassName="delivery-status-row"
            />

            {isConcreteTestModalVisible && (
                <MultipleConcreteTestModal
                    concreteLoadId={concreteLoadId}
                    open={isConcreteTestModalVisible}
                    setOpen={setIsConcreteTestModalVisible}
                    initialFormValues={initialFormValuesConcreteTestModal}
                    resetInitialFormValuesConcreteTestModal={resetInitialFormValuesConcreteTestModal}
                    deliveryNoteNumber={deliveryNoteNo}
                />
            )}

            {reportModalVisible && (
                <ReportArrivalModal
                    concreteLoadId={concreteLoadId}
                    deliveryNoteNo={deliveryNoteNo}
                    licensePlate={licensePlate}
                    open={reportModalVisible}
                    setOpen={setReportModalVisible}
                    onComplete={() => setRefreshTable(true)}
                />
            )}

            {unloadingModalVisible && (
                <StartUnloadingModal
                    concreteLoadId={concreteLoadId}
                    deliveryNoteNo={deliveryNoteNo}
                    licensePlate={licensePlate}
                    processableUntil={processableUntil}
                    arrivalDateTime={arrivalDateTime}
                    open={unloadingModalVisible}
                    setOpen={setUnloadingModalVisible}
                    onComplete={() => setRefreshTable(true)}
                />
            )}

            {unloadedModalVisible && (
                <EndUnloadingModal
                    concreteLoadId={concreteLoadId}
                    deliveryNoteNo={deliveryNoteNo}
                    licensePlate={licensePlate}
                    startUnloadingDateTime={startUnloadingDateTime}
                    open={unloadedModalVisible}
                    setOpen={setUnloadedModalVisible}
                    setAcceptAndSignModalVisible={setAcceptAndSignModalVisible}
                    onComplete={() => setRefreshTable(true)}
                />
            )}

            {rejectConcreteLoadModalVisible && deliveryDetails && (
                <RejectConcreteLoadModal
                    deliveryNoteDetailsBP={deliveryDetails}
                    open={rejectConcreteLoadModalVisible}
                    setOpen={setRejectConcreteLoadModalVisible}
                    onComplete={() => {
                        setRejectConcreteLoadModalVisible(false);
                        setRefreshTable(true);
                    }}
                />
            )}

            {acceptAndSignModalVisible && (
                <AcceptAndSignModal
                    projectId={projectId}
                    orderId={orderId}
                    concreteLoadId={concreteLoadId}
                    open={acceptAndSignModalVisible}
                    setOpen={setAcceptAndSignModalVisible}
                    fromDeliveryDetailsPage
                    onComplete={() => setRefreshTable(true)}
                />
            )}

            {uploadDeliveryNoteModalVisible && (
                <UploadDeliveryNoteModal
                    concreteLoadId={concreteLoadId}
                    open={uploadDeliveryNoteModalVisible}
                    setOpen={setUploadDeliveryNoteModalVisible}
                    onComplete={() => setRefreshTable(true)}
                />
            )}
        </>
    );
}

export default DeliveryStatusTable;
