import { FreshConcreteTest, TestSpecimen } from '@nexploretechnology/concreting-core-client';
import {
    AttachmentBasicInfo,
    ConcreteLoadIngredientOverviewResponse,
    DeliveryNoteDetailsBP,
    DeliveryNoteFullDetails,
    DeliveryWater,
    DeliveryWaterOverview
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { EvaporationRate } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';
import {
    ConcreteLoadDataLite,
    SupplierConcreteLoad
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { useCallback, useMemo, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

export interface DeliveryNoteAllDetails extends DeliveryNoteFullDetails {
    deliveryDetails: DeliveryNoteDetailsBP;
    supplierConcreteLoad?: SupplierConcreteLoad;
    evaporationRate: EvaporationRate;
    attachmentBasicInfo: AttachmentBasicInfo;
    deliveryWater: DeliveryWater[];
    deliveryWaterOverview: DeliveryWaterOverview;
}

interface Props {
    orderId: string;
    concreteLoadId: string;
}

export function useDeliveryNoteDetails({ orderId, concreteLoadId }: Props) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [loading, setLoading] = useState<boolean>(false);

    const [deliveryDetails, setDeliveryDetails] = useState<DeliveryNoteDetailsBP>(null);
    const [deliveryFullDetails, setDeliveryFullDetails] = useState<DeliveryNoteFullDetails>(null);
    const [concreteLoadIngredients, setConcreteLoadIngredients] =
        useState<ConcreteLoadIngredientOverviewResponse>(null);
    const [concreteLoadDataLite, setConcreteLoadDataLite] = useState<ConcreteLoadDataLite>();
    const [supplierConcreteLoad, setSupplierConcreteLoad] = useState<SupplierConcreteLoad>(null);
    const [tests, setTests] = useState<{ freshConcreteTests?: FreshConcreteTest[]; testSpecimens?: TestSpecimen[] }>(
        {}
    );

    const [evaporationRate, setEvaporationRate] = useState<EvaporationRate>(null);
    const [attachmentBasicInfo, setAttachmentBasicInfo] = useState<AttachmentBasicInfo>(null);
    const [deliveryWater, setDeliveryWater] = useState<DeliveryWater[]>();
    const [deliveryWaterOverview, setDeliveryWaterOverview] = useState<DeliveryWaterOverview>();

    const loadDetails = useCallback(async () => {
        setLoading(true);

        await api.deliveryNoteClient
            .getDeliveryNotePageData({
                projectId,
                orderId,
                companyId,
                concreteLoadId
            })
            .then((response) => {
                if (response.isSuccess()) {
                    const responseEntity = response.getEntity();
                    setTests({
                        freshConcreteTests: responseEntity.freshConcreteTests,
                        testSpecimens: responseEntity.testSpecimens
                    });
                    setDeliveryFullDetails(responseEntity.concreteLoadDetails);
                    setDeliveryDetails(responseEntity.deliveryNoteDetails);
                    setSupplierConcreteLoad(responseEntity.deliveryNoteId);
                    setConcreteLoadIngredients(responseEntity.concreteLoadIngredients);
                    setConcreteLoadDataLite(responseEntity.concreteLoadLite);
                    setEvaporationRate(responseEntity.evaporationRate);
                    setDeliveryWater(responseEntity.concreteLoadDetails.concreteLoad.deliveryWater);
                    setDeliveryWaterOverview(responseEntity.concreteLoadDetails.concreteLoad.deliveryWaterOverview);
                } else {
                    setError({ message: response.getError() });
                }
            });

        await api.deliveryNoteClient.deliveryNoteGetFile(companyId, projectId, concreteLoadId).then((response) => {
            if (response.isSuccess()) {
                const responseEntity = response.getEntity();
                setAttachmentBasicInfo(responseEntity);
            }
        });
    }, [api.deliveryNoteClient, projectId, orderId, companyId, concreteLoadId]);

    const deliveryAllDetails = useMemo(() => {
        if (!deliveryDetails || !deliveryFullDetails) return null;
        return {
            ...deliveryFullDetails,
            deliveryDetails,
            supplierConcreteLoad,
            evaporationRate,
            attachmentBasicInfo,
            deliveryWater,
            deliveryWaterOverview
        } as DeliveryNoteAllDetails;
    }, [
        deliveryDetails,
        deliveryFullDetails,
        supplierConcreteLoad,
        evaporationRate,
        attachmentBasicInfo,
        deliveryWater,
        deliveryWaterOverview
    ]);

    return {
        supplierConcreteLoad,
        deliveryDetails,
        deliveryFullDetails,
        concreteLoadIngredients,
        deliveryAllDetails,
        error,
        loading,
        concreteLoadDataLite,
        tests,
        loadDetails
    };
}

export default useDeliveryNoteDetails;
