import { t, Trans } from '@lingui/macro';
import { DeliveryWaterOverview } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Alert, Card, Col, Form, Input, Row, Spin } from 'antd';
import AmountCol from 'src/app-react/business-page/delivery-note-details/components/AmountCol';
import DateTimePicker from 'src/app-react/components/Form/DateTimePicker';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import units from 'src/app-react/constants/marketsData/marketDataAUS/dataValues/units';

interface LayoutDefaultProps {
    concreteLoadId: string;
    deliveryNumber: string;
    truckNumber: string;
    deliveryWaterOverview?: DeliveryWaterOverview;
    maxWaterExceedWarningVisible: boolean;
}
export default function LayoutDefault({
    concreteLoadId,
    deliveryNumber,
    truckNumber,
    deliveryWaterOverview,
    maxWaterExceedWarningVisible
}: LayoutDefaultProps) {
    return (
        <>
            {deliveryWaterOverview ? (
                <Card data-testid="water-on-site-amount-card-default" style={{ backgroundColor: '#E7EDF5' }}>
                    <Row>
                        <AmountCol span={8} title={t`Delivery docket number`}>
                            {deliveryNumber}
                        </AmountCol>
                        <AmountCol span={8} title={t`Truck number`}>
                            {truckNumber}
                        </AmountCol>
                    </Row>
                    <Row>
                        <AmountCol span={8} title={t`Max. water to add`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview?.maxWaterToAdd === undefined
                                        ? '-'
                                        : deliveryWaterOverview.maxWaterToAdd
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                        <AmountCol span={8} title={t`Total water added on site`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview?.totalWaterOnSite === undefined
                                        ? '-'
                                        : deliveryWaterOverview.totalWaterOnSite
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                        <AmountCol span={8} title={t`Remaining water for addition`}>
                            <NumberFormatter
                                value={
                                    deliveryWaterOverview?.remainignWaterAddition === undefined
                                        ? '-'
                                        : deliveryWaterOverview.remainignWaterAddition
                                }
                                unit={units.water.toLocaleUpperCase()}
                            />
                        </AmountCol>
                    </Row>
                </Card>
            ) : (
                <Card
                    data-testid="water-on-site-amount-card-default"
                    style={{
                        backgroundColor: '#E7EDF5',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <Spin style={{ display: 'block', marginBottom: '1rem' }} />
                    <Trans>Loading delivery water overview</Trans>
                </Card>
            )}

            <p style={{ marginTop: '1rem' }}>
                <Trans>Please enter the time when the truck arrived at the construction site.</Trans>
            </p>

            <Row gutter={8}>
                <Col span="12">
                    <Form.Item
                        name="waterQuantity"
                        style={{ fontWeight: 'bold' }}
                        label={<Trans>Added water on site</Trans>}
                        rules={[numberRule({ min: 1, required: true, decimal: false })]}>
                        <Input
                            type="number"
                            min="1"
                            data-testid="waterQuantity"
                            addonAfter={units.water}
                            placeholder={t`Enter the water quantity`}
                        />
                    </Form.Item>
                </Col>
                <Col span="12">
                    <Form.Item
                        label={<Trans>Addition Time</Trans>}
                        style={{ fontWeight: 'bold' }}
                        name="additionTime"
                        rules={[{ required: true, message: t`Please input time` }]}>
                        <DateTimePicker data-testid="date-input" placeholder={t`Enter time`} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                {maxWaterExceedWarningVisible && (
                    <Alert
                        type="warning"
                        showIcon
                        description={t`By adding that amount of water you will be exceeding the preestablished Max. water to add value.`}
                    />
                )}
            </Row>
            <Row gutter={8}>
                <Col span="24">
                    <Form.Item name="comments" style={{ fontWeight: 'bold' }} label={<Trans>Comment</Trans>}>
                        <Input.TextArea
                            data-testid="comment-input"
                            placeholder={t`Enter comments`}
                            autoSize={{ minRows: 1, maxRows: 20 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
